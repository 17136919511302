import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, isRef as _isRef, Transition as _Transition, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/image/ai/s1.png'
import _imports_1 from '@/assets/image/ai/s2.png'
import _imports_2 from '@/assets/image/ai/s3.png'


const _hoisted_1 = { class: "mt-ais" }
const _hoisted_2 = { class: "mt-ais-type" }
const _hoisted_3 = { style: {"margin-top":"30px"} }

import {computed, ref} from "vue";
import GotoLesson from "@/components/timetable/GotoLesson.vue";
import TimetableTitle from "@/components/timetable/TimetableTitle.vue";
import AIStyleTransfer from "@/components/ai/AIStyleTransfer.vue";
import CloseIcon from "@/components/system/CloseIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MaterialAIs',
  setup(__props) {

let checkType = ref(99); // 99 选择     0-文生图、图生图 2-线稿上色 4-风格切换

let checkName = computed(() => {
  switch (checkType.value) {
    case 0:
      return '风格迁移'
    case 2:
      return '线稿上色'
    case 4:
      return '照片转绘'
    default:
      return ''
  }
})



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _withDirectives(_createVNode(TimetableTitle, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(checkName)), 1)
        ]),
        _: 1
      }, 512), [
        [_vShow, _unref(checkType) !== 99]
      ]),
      _createVNode(CloseIcon)
    ]),
    _createVNode(_Transition, { "leave-active-class": "animate__animated animate__fadeOutLeft" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "mt-ais-t-border",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(checkType) //@ts-ignore
 ? checkType.value = 0 : checkType = 0))
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: ""
            }, null, -1),
            _createElementVNode("div", {
              class: "mt-ais-t-text",
              style: {"color":"#FEA92F"}
            }, "风格迁移", -1)
          ])),
          _createElementVNode("div", {
            class: "mt-ais-t-border",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_isRef(checkType) //@ts-ignore
 ? checkType.value = 2 : checkType = 2))
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("img", {
              src: _imports_1,
              alt: ""
            }, null, -1),
            _createElementVNode("div", {
              class: "mt-ais-t-text",
              style: {"color":"#FF8281"}
            }, "线稿上色", -1)
          ])),
          _createElementVNode("div", {
            class: "mt-ais-t-border",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_isRef(checkType) //@ts-ignore
 ? checkType.value = 4 : checkType = 4))
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("img", {
              src: _imports_2,
              alt: ""
            }, null, -1),
            _createElementVNode("div", {
              class: "mt-ais-t-text",
              style: {"color":"#FF905F"}
            }, "照片转绘", -1)
          ]))
        ], 512), [
          [_vShow, _unref(checkType) === 99]
        ])
      ]),
      _: 1
    }),
    _createVNode(_Transition, { "enter-active-class": "animate__animated animate__fadeInRight" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createVNode(AIStyleTransfer, { type: _unref(checkType) }, null, 8, ["type"])
        ], 512), [
          [_vShow, _unref(checkType) !== 99]
        ])
      ]),
      _: 1
    }),
    _createVNode(GotoLesson)
  ]))
}
}

})