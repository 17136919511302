import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, isRef as _isRef, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "img-list primary-scroll" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]

import {reactive, ref} from "vue";
import service from "@/utils/axios";
import {useRoute} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'MaterialLibrary',
  setup(__props, { expose: __expose }) {

let route = useRoute()
let choose = ref(null)


let onInit = () => {
  choose.value = null;
  onSearch()
}
let onSearch = () => {
  service.post('/dwart/an_teaching/classCourse/findTeacherTopicWare', {
    topicId: route.query.topicId,
    classId: route.query.classId
  }).then(res => {
    console.log(res);
    dataRef.imgs = [];
    dataRef.imgs = res;
  })
}

let confirmChoose = () => {
  return dataRef.imgs.find(i => i.id === choose.value);
}

let dataRef = reactive({
  imgs: [] as any[],
})

__expose({confirmChoose, onInit})



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataRef).imgs, (i, k) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["img-item", {'choose': _unref(choose) === i.id}]),
          key: k,
          onClick: ($event: any) => (_isRef(choose) //@ts-ignore
 ? choose.value = i.id : choose = i.id)
        }, [
          _createElementVNode("img", {
            src: i.sourceUrl,
            alt: ""
          }, null, 8, _hoisted_3)
        ], 10, _hoisted_2))
      }), 128))
    ])
  ]))
}
}

})